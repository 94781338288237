<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <b-card v-else>
    <b-row>
      <b-col
        v-for="(survey, index) in surveys"
        :key="index"
        sm="6"
        lg="4"
        cols="12"
        class="mb-2"
      >
        <b-card
          border-variant="primary"
          tag="article"
        >
          <b-card-text>
            <b-card-title class="text-truncate mb-1">
              {{ survey.name }}
            </b-card-title>
            <p 
              v-if="isMatchSurvey(survey)"
            >
              Match: {{ getMatchUsername(survey) }}
            </p>
            <p class="mb-0">
              <b-badge
                v-for="role in survey.roles"
                :key="role"
                variant="primary"
                class="font-weight-normal"
                style="margin-right: 2px;"
              >
                {{ role }}
              </b-badge>
            </p>
          </b-card-text>

          <template>
            <b-dropdown-divider
              style="list-style-type: none"
            />

            <b-card-text class="mt-1">
              <b-button
                variant="primary"
                @click="completedSurveys(survey)"
              >
                {{ getCompleteButtonText(survey.status) }}
              </b-button>
            </b-card-text>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <section v-if="surveys.length === 0">
      <div class="text-center m-4">
        <b-avatar
          size="72"
          variant="primary"
          class="mr-2"
        >
          <feather-icon
            icon="SearchIcon"
            size="42"
          />
        </b-avatar>
        <h3 class="mt-1">
          No Surveys found
        </h3>
        <p class="text-muted">
          There weren't any Surveys found or available for you to complete.
        </p>
      </div>
    </section>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BButton,
  BBadge,
  BCardText,
  BRow,
  BCol,
  BCardTitle,
  BDropdownDivider,
  BAvatar
} from "bootstrap-vue";
import programsService from "@/services/programsService";
import { makeErrorToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles, formStatus } from "@/models";

export default {
  name: "ParticipantsSurvey",
  components: {
    BCard,
    BButton,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BCardTitle,
    BDropdownDivider,
    AomSkeletonLoader,
    BAvatar
  },
  data() {
    return {
      isLoading: false,
      surveys: []
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),
  },

  created() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getSurveysByProgramIdAndUserId(
          this.program.id,
          this.profile.id
        );
        this.surveys = response.data.items.map(item => ({
          ...item,
          name: item.form.translations[0]?.title,
          roles: item.form.roles
            .map(r => {
              if (r.id === userRoles.MENTOR) {
                return this.mentorDisplay;
              }
              if (r.id === userRoles.MENTEE) {
                return this.menteeDisplay;
              }
              return "";
            })
        }));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        this.$toast(makeErrorToast("Surveys list not loaded."));
        return;
      } finally {
        this.isLoading = false;
      }
    },
    isMatchSurvey (survey) {
      return survey.match_id !== null;
    },
    getMatchUsername (survey) {
      if (survey.program_match) {
        const user = survey.program_match.users.filter(u => u.id !== this.profile.id);
        return user.length?user[0].full_name: '';
      }
    },
    getCompleteButtonText(status) {
      if (!status) { return 'Complete Survey'; }

      if (status.id === formStatus.COMPLETED) {
        return "Re-take Survey";
      }

      return "Complete Survey";
    },

    completedSurveys(survey) {
      if (survey.match_id) {
        this.$router.push({
          name: "participant-match-survey-overview",
          params: {
            form: survey.form.id,
            match: survey.match_id
          },
        });
      } else {
        this.$router.push({
          name: "participant-survey-overview",
          params: {
            form: survey.form.id,
          },
        });
      }
    }
  },
};
</script>
